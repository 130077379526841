.options__container {
    display: flex;
    flex-direction: column;
    flex-basis: 60%;
    justify-content: space-evenly;
    button {
        color: black;
        flex-basis: 35%;
        border-radius: 5vh;
        border: 1px solid #F2F2F2;
        background: $lightGray;
        font-family: "ProximaNovaBold", sans-serif;
        outline: none;
        font-size: 2.3vh;
    }
}
.options-btn--selected {
    background: $darkBlue !important;
    color: white !important;
}
.moving-distance--flex--column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 37vh;
}
.continue-btn {
    flex-basis: 20%;
}
.continue-btn--disabled {
    background: #BDBDBD;
}

////////////////// iPad ////////////////////////

@media (min-width: 768px) {
    .options__container {
        button {
            font-size: 2vh;
        }
    }
}