.review-mpid__row {
  display: flex;
  justify-content: space-between;
  padding-top: 2.2vh;
  p {
    margin-bottom: 0;
    font-size: 2vh;
  }
  .edit {
    color: #F57C14;
  }
}
.review-address__container {
  border-bottom: 1px solid #bdbdbd;
  padding: 2.5vh 0;
  h2 {
    color: #27bde4;    
    letter-spacing: -0.012em;
    font-size: 2vh;
  }
  .height {
    color: $textGrey;
    font-size: 2vh;
    margin-bottom: 0.3vh;
  }
  .street {
    font-size: 2.4vh;
    color: #2F354C;
    margin-bottom: 0.3vh;
  }
  .locality {
    font-size: 2vh;
    color: #2F354C;
    margin-bottom: 0;
  }
}
.review-move-time__row {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #bdbdbd;
  padding: 3vh 0vh;
  p {
    font-size: 2vh;
    margin-bottom: 0;
  }
}

////////////////////////// Footer ///////////////////////////////
.footer__container--white {
  height: 38vh;
  background: white;
}
.footer__inner__container--white {
  padding: 20px 55px 40px 55px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
      color: $darkBlue;
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 2vh;
      margin-bottom: 10px;
  }
}
.footer__logo--blue {
  display: flex;
  justify-content: center;
  flex-basis: 40%;
  width: 90%;
  background: url("/img/review/unpakt-logo--large.svg") no-repeat center;
  background-size: contain;
  align-self: center;
}
.footer-social__container {
  display: flex;    
  flex-basis: 10%;
}
.question-mark {
  background: url("/img/new_design/questionMark.png") no-repeat center;
  background-size: contain;
  height: 2.8vh;
  flex-basis: 20%;
}
.footer-social--facebook--color {
  background: url("/img/review/facebook-color.svg") no-repeat center;
  background-size: contain;
  flex-basis: 25%;
}
.footer-social--instagram--color {
  background: url("/img/review/instagram-color.svg") no-repeat center;
  background-size: contain;
  flex-basis: 25%;
}
.footer-social--linkedin--color {
  background: url("/img/review/linkedin-color.svg") no-repeat center;
  background-size: contain;
  flex-basis: 25%;
}
.footer-social--youtube--color {
  background: url("/img/review/youtube-color.svg") no-repeat center;
  background-size: contain;
  flex-basis: 25%;
}
.footer__button {
  flex-basis: 15%;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F2F2F2;
  span {
      flex-basis: 70%;
      text-align: left;
      font-weight: 500;
      font-size: 1.9vh;
      text-decoration: underline;
      font-family: Arial, Helvetica, sans-serif;
  }
}
.next-item-ul {
  margin-top: 3vh;
  background: #f2f2f2;
  height: auto;
  padding: 3% 6%;
  width: 100vw;
  margin-bottom: 2vh;
}
.next-item-li {
  list-style: none;
  justify-content: space-between;
  p {
    font-size: 14px;
    line-height: 18px;
  }
}
.next-item-icon {
  width: 23px;
  height: 23px;
  float: left;
  font-family: ProximaNovaBold, sans-serif;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: rgb(255, 255, 255);
  background: rgb(247, 128, 30);
  margin-right: 17px;
  border-radius: 100%;
}