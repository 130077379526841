.address-section__container {
    height: auto;
    margin: 2vh 0;
}
.address-section__innner_container {
    padding: 3%;
    border: 1px solid #bdbdbd;
    border-radius: 1vh;
    p {
        font-family: Arial, Helvetica, sans-serif;
    }
    .address-section__title {
        color: #27bde4;
        font-weight: 500;
        letter-spacing: -0.012em;
        font-size: 2vh;
        display: flex;
        align-items: center;
    }
    .address-section__title--div {
        color: #27bde4;
        font-weight: 500;
        letter-spacing: -0.012em;
        font-size: 2vh;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .circle {
            width: 0.8vh;
            height: 0.8vh;
            background: $lightBlue;
            border-radius: 50%;
            margin: 0 1vh;
        }
    }
}
.address-section__address__container {
    border-bottom: 1px solid #bdbdbd;
}
.address-section__address__inner__container {
    display: flex;
    justify-content: space-between;
}
.address__icon--pickup {
    background: url("/img/addresses/pickup_icon.png") no-repeat center;
    background-size: contain;
    height: 3vh;
    flex-basis: 8%;
    margin-top: 0.4vh;
}
.address__icon--dropoff {
    background: url("/img/addresses/dropoff_icon.png") no-repeat center;
    background-size: contain;
    height: 3vh;
    flex-basis: 8%;    
    margin-top: 0.4vh;
}
.address__icon--extra {
    background: url("/img/addresses/another_stop_icon.png") no-repeat center;
    background-size: contain;
    height: 3vh;
    flex-basis: 8%;
    margin-top: 0.4vh;
}
.address-section__info__container {
    height: 7vh;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
    &.coi {
        height: auto;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        p {
            margin-bottom: 0;
        }
    }
    &.floor {
        align-items: center;
    }
}
.coi__text {
    font-size: 2.3vh;
}
.address-section__info__block {
    display: flex;
    align-items: center;
    flex-basis: 25%;
    border-bottom: 1px solid #bdbdbd;
    .address-section__info__block__input {
        border: none;
        background: white;
        font-family: Helvetica Neue;
        font-weight: 500;
    }
}
.height-select__container {
    position: relative;
    flex-basis: 30%;
}
.height-select {
    align-self: center;
    flex-basis: 55%;
}
.height-select__floor {
    align-self: center;    
}
.coi__container {
    height: auto;
}
.insurance-information__container {
    p {
        color: $lightBlue;
        font-size: 2vh;
    }
}
.insurance-information__title {
    display: flex;
    justify-content: flex-start;
    height: 4vh;
}
.insurance-information__content {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #bdbdbd;
    margin-bottom: 2vh;
    padding-bottom: 1.4vh;
    input {
        border: none !important;
    }
}
.coi__icon {
    height: 2.3vh;
    width: 2.3vh;
    margin-right: 1vh;
    &.user {
        background: url("/img/addresses/user.png") no-repeat center;
        background-size: contain;
    }
    &.phone {
        background: url("/img/addresses/phone.png") no-repeat center;
        background-size: contain;
    }
}
.coi-input {
    &::placeholder {
        color: #bdbdbd;
    }
}
.extra-stop-selection__row {
    display: flex;
    justify-content: flex-start;
}
.check-box__label {
    display: flex;
    align-items: center;
}
.check-box__input {
    margin-right: 3vw;
    width: 5.5vw;
    height: 5.5vh;
}
.radio-btns__row {
    display: flex;
    justify-content: flex-end;
    label {
        margin-right: 2vw;
        display: flex;
        align-items: center;
        input {
            margin-right: 2vw;
            width: 4vw;
            height: 4vh;
        }
    }
}
.continue-disable {
    background:#bdbdbd;
}

//////////////////   Geosuggest   //////////////////

.geosuggest__input {
    border: none !important;
    font-family: Helvetica Neue;
    font-weight: 500;
}
.geosuggest {
    flex-basis: 88%;
    padding-bottom: 1.7vh;
}
.geo-input-error {
    border-bottom: 1px solid #ff737f;
    position: relative;
}
.field-required-msg {
    position: absolute;
    color: red;
    font-size: 1.4vh;
    font-style: italic;
    margin-bottom: 0;
}

//////////////////   Smaller Screen   //////////////////

@media (max-width: 376px) {
    .coi__text {
        font-size: 15.7px;
        letter-spacing: -0.3px;
    }
}