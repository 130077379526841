.avatar {
    // background: url("/img/common/avatar.png") no-repeat center;
    background: url("/img/common/photo2.png") no-repeat center;
    background-size: contain;
    position: absolute;
    height: 8.2vh;
    width: 8.2vh;
    bottom: -4.2vh;
    left: 43vw;
    border-radius: 50%;
}
.back-arrow__container {
    display: flex;
    justify-content: flex-start;
    a {
        background: url("/img/common/back_arrow.png") no-repeat center;
        background-size: contain;
        height: 8vh;
        flex-basis: 8%;
    }
}
.close__container {
    display: flex;
    justify-content: flex-end;
    a {
        background: url("/img/common/close.svg") no-repeat center;
        background-size: contain;
        height: 8vh;
        flex-basis: 5%;
    }
}
.landing-padding {
    padding-left: 6%;
    padding-right: 6%;
}
.moving-details-padding {
    padding: 3% 6%;
    &.thin {
        padding: 3% 2%;
    }
}
.casey-words__container {
    p {
        line-height: 4vh;                
        font-size: 3vh;    
        text-align: center;
        margin-bottom: 0;
    }
    span {
        color: $orange;
    }
}
.casey-words__container--white {
    p {
        color: white;
    }
}
.button--long--orange {
    background: $orange;
    border-radius: 1vh;
    border: none;
    height: 7vh;
    color: white;
    font-family: "ProximaNovaBold", sans-serif;
    font-size: 2.2vh;
    font-weight: 700;
    letter-spacing: 0.107em;
    &.disabled-button {
        background: #dddddd;
    }
}
.button--long--round {
    width: 100%;
    border: none;    
    background: #F2F2F2;
    border-radius: 28px;
    height: 6.2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.7vh;
    outline: none !important;
    p {
        margin-left: 2.5vh;
        font-size: 2vh;
        font-weight: 600;
        font-family: Montserrat;
        margin-bottom: 0;
    }
}
.button--long--blue {
    background: $unpaktBlue;
    border-radius: 1vh;
    border: none;
    height: 7vh;
    color: white;
    font-family: Helvetica Neue;
    font-size: 2vh;
    font-weight: 700;
    letter-spacing: 0.107em;
}
.hel-font {
    font-family: Helvetica Neue;
    font-weight: 500;
    letter-spacing: 0.107em;
}
.hel-font__text {
    font-family: Helvetica Neue;
    font-weight: 500;
    letter-spacing: 0.012em;
}
.hel-font__text--small {
    font-family: Helvetica Neue;
    font-weight: 300;
    letter-spacing: 0.012em;
}
.hel-font__text--medium {
    font-family: Helvetica Neue;
    font-weight: 400;
    letter-spacing: 0.012em;
}
.hel-font__text--bold {
    font-family: Helvetica Neue;
    font-weight: 700;
    letter-spacing: 0.012em;
}
.hel-font__text--large {
    font-family: Helvetica Neue;
    font-weight: 500;
    letter-spacing: 0.012em;
    font-size: 3vh;
}
.hel-font__text--gray {
    font-family: Helvetica Neue;
    font-weight: 500;
    letter-spacing: 0.107em;
    color: #828282;
}
.hel-font__button {
    font-family: Helvetica Neue;
    font-weight: 700;
    letter-spacing: 0.107em;
}
.hel-font__button--bold {
    font-family: Helvetica Neue;
    font-weight: bold;
    letter-spacing: 0.107em;
}
.gray-section {
    background: $normalgray;
    padding: 3vw;
    margin-bottom: 3vh;
    p {
        margin-bottom: 0;
        font-size: 2vh;
    }
    &.flexbox {
        display: flex;
        justify-content: space-around;
    }
    &.flexbox--center {
        display: flex;
        justify-content: center;
    }
    &.gridbox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 0.9fr;
        grid-template-areas:
            "ic hm hm"
            "ct ct ct"
            "bt bt bt";
    }
    &.small {
        height: 6vh;
    }
    &.large {
        height: 31vh;
    }
}
.underline {
    text-decoration: underline;
}
.row {
    margin: 0;
}
.loader-gif {
    position: fixed;
    top: 40vh;
    left: 30vw;
}

.container__layout {
    margin-top: 3.3vh;
}
.container__title {
    font-size: 2.8vh;
    padding-bottom: 2vh;
    margin-bottom: 0;
    border-bottom: 1px solid #bdbdbd;
}
.red-error-message {
    position: absolute;
    color: red;
    font-size: 1.5vh;    
}
.red-error-border {
    border: 1px solid red !important;
}

.prox-font--bold {
    font-family: "ProximaNovaBold", sans-serif !important;
    font-weight: 600;    
}
.prox-font--regular {
    font-family: "ProximaNovaRegular", sans-serif !important; 
}


////////////////// iPad ////////////////////////

@media (min-width: 768px) {
    .gray-section {
        &.small {
            height: 7.5vh;
        }
    }
}
