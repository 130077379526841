.payment__body__container {
  padding: 3vh 0;
  &.account {
    padding-bottom: 1vh;
  }
}
.payment-info__item {
  position: relative;
  margin-bottom: 3vh;
  p {
    font-size: 2.5vh;
    margin-bottom: 1vh;
  }
  input {
    height: 7vh;
    padding-left: 5vw;
    font-size: 2.2vh;
  }
  input::placeholder {
    color: #bdbdbd;
    font-size: 2.2vh;
  }
  img {
    position: absolute;
    height: 3vh;
    right: 3vw;
    bottom: 2vh;
  }
  .billing {
    height: 7vh !important;
    background-color: white !important;
  }
}
.payment-info__row {
  display: flex;
  justify-content: space-between;
  .payment-info__item,
  .account-info__item {
    flex-basis: 48%;
  }
}

.account-info__item {
  position: relative;
  margin-bottom: 2.7vh;
  p {
    font-size: 2.8vh;
    margin-bottom: 1vh;
  }
  input {
    height: 7vh;
    padding-left: 3vw;
    font-size: 2.2vh;
  }
  input::placeholder {
    color: #bdbdbd;
    font-size: 2vh;
    letter-spacing: -0.5px;
  }
  &.last {
    margin-bottom: 1vh;
  }
}
.already-have-account {
  font-size: 1.8vh !important;
}

.gray-section--payment {
  background: $normalgray;
  padding: 2vw;
  margin-bottom: 3vh;
}

.what-is-next-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5vh;
  .what-is-next-row__container {
    flex-basis: 15%;
    display: flex;
    align-items: center;
  }
  p {
    flex-basis: 83%;
    font-size: 15px;
    margin-bottom: 0;
  }
}
.what-is-next-icon {
  width: 50px;
  height: 50px;
  &.confirmation {
    background: url("/img/payment/icon_confirmation.svg") no-repeat center;
    background-size: contain;
  }
  &.creditcard {
    background: url("/img/payment/icon_creditcard.svg") no-repeat center;
    background-size: contain;
  }
  &.contact {
    background: url("/img/payment/icon_contact.svg") no-repeat center;
    background-size: contain;
  }
  &.checklist {
    background: url("/img/payment/icon_checklist.svg") no-repeat center;
    background-size: contain;
  }
}
.cancellation-statement {
  color: rgb(30, 205, 0);
  font-size: 1rem;
}
.what-next__payment__container {
  margin-top: 3vh;
  background: #f2f2f2;
  height: auto;
  padding: 3% 6%;
  width: 100vw;
}
//////////////////   Smaller Screen   //////////////////

@media (max-width: 376px) {
  .what-is-next-row {
    .what-is-next-row__container {
      flex-basis: 15%;
    }
    p {
      flex-basis: 78%;
      font-size: 14px;
    }
  }
}
