.compare-home-size__row {
  margin: 3vh 0;
}
.pricing-equation__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3vh;
}
.equation-icon {
  margin: 15px 0;
  background-size: contain;
  height: 2.7vh;
  width: 2.7vh;
  &.x {
    background: url("/img/compare/x.svg") no-repeat center;
  }
  &.plus {
    background: url("/img/compare/+.svg") no-repeat center;
  }
  &.equal {
    background: url("/img/compare/=.svg") no-repeat center;
  }
}
.movers__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mover-card {
  width: 100%;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1.2vh 1vh 0.3vh 1vh;
}
.mover-rating__row,
.mover-info__row {
  display: flex;
  justify-content: space-between;
}
.mover-info__row {
  display: flex;
  justify-content: space-between;  
  margin-top: 1vh;
  margin-bottom: 0.4vh;
  .mover-logo {
    flex-basis: 16%;
    height: 6.7vh;
    border-radius: 8px;
    filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.12));
  }
  .mover-info {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    padding-top: 0.5vh;
    p {
      font-size: 3.7vw;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 2vh;
      letter-spacing: -0.5px;
    }
    .mover-price {
      font-size: 6vw;
      letter-spacing: -0.3px;
    }
  }
  button {
    flex-basis: 28%;
  }
}
.mover-continue__btn {
  font-size: 2vh;
}
.num-reviews {
  color: $textGrey;
}
.mover-profile__container {
  border-top: 1px solid #e0e0e0;
  margin-top: 1.3vh;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 1.3vh;
  display: flex;
  justify-content: space-evenly;
  padding: 0.8vh 3vw;
}
.profile-block {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  p {
    margin-bottom: 0;
    text-align: center;
  }
  p:nth-child(1) {
    font-size: 3vh;
  }
  p:nth-child(2) {
    font-size: 1.6vh;
    line-height: 2vh;
  }
}
.addition-services__container {
  display: flex;
}
.MuiChip-outlined {
  border: 1px solid #27bde4 !important;
  color: #27bde4 !important;
  margin: 3px;
}
.MuiChip-label {
  font-family: Helvetica Neue;
  font-weight: 500;
  letter-spacing: 0.012em;
}
.full-description {
  padding-top: 7px;
  a {
    color: $orange !important;
    text-decoration: underline;
  }
}
.ratings__row {
  margin-top: 2vh;
  display: flex;
  justify-content: space-between;
}
.unpakt-rating {
  display: flex;
  align-items: center;
  font-size: 1.8vh;
}
.unpakt-logo--small {
  background: url("/img/compare/unpakt_logo-small.svg") no-repeat center;
  background-size: contain;
  width: 12vw;
  height: 3vh;
  margin-right: 1.5vw;
}
.third-party-rating {
  display: flex;
  align-items: center;
  p {
    font-weight: bolder;
    font-size: 2vh;
    letter-spacing: -0.4px;
    color: #27bde4;
    margin-bottom: 0;
    margin-right: 2vw;
  }
}
.compare-footer__container {
  padding-top: 3vh;
  padding-bottom: 3vh;
  position: absolute;
  left: 0;
}
.compare-footer__title {
  display: flex;
  justify-content: flex-start;
}
.footer__logo {
  background: url("/img/compare/unpakt_logo-small.svg") no-repeat center;
  background-size: contain;
  flex-basis: 25%;
  height: 4vh;
  margin-right: 3vw;
}
.expand-lines__container {
  display: flex;
  justify-content: center;
  position: relative;
  height: 0.7vh;
}
.expand-lines__row {
  display: flex;
  flex-basis: 5%;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: white;
  height: 1.3vh;
  width: 14%;
  bottom: -1.6vh;
  border-left: 1px solid #bdbdbd;
  border-right: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.expand-lines {
  flex-basis: 50%;
  display: flex;
  height: 0.5vh;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  flex-direction: column;
}
.down-arrow {
  background: url("/img/compare/down-arrow.svg") no-repeat center;
  height: 1.3vh;
  width: 1.3vh;
}


////////////////// iPad ////////////////////////

@media (min-width: 768px) {
  .mover-info__row {
    .mover-info {
      flex-basis: 43%;
      p {
        font-size: 3vw;
        line-height: 2.5vh;
      }
      .mover-price {
        font-size: 4vw;
      }
    }
    .mover-logo {
      flex-basis: 18%;
      height: 9vh;
    }
  }
  .mover-rating__row {
    span {
      font-size: 1.8vh;
    }
  }
  .supermover {
    height: 2.5vh;
  }
  .review-star {
    height: 2vh;    
  }
}
