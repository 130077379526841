.review-total__container {
  margin-top: 3.3vh;
}
.review-total__title__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 1.3vh;
  h1 {
    font-size: 2.8vh;
    margin-bottom: 0;
  }
  p {
    font-size: 2vh;
    margin-bottom: 0;
  }
}
.review-total__body {
  padding-top: 1.3vh;
}
.review-section {
  padding: 2vh 0;
  border-bottom: 1px solid #bdbdbd;
}
.review-summary__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
  p {
    margin-bottom: 0;
    font-size: 2vh;
  }
}
.promocode__title {
  color: #27bde4;
  font-size: 1.5vh;
  margin-bottom: 1vh;
}
.promocode__input__row {
  display: flex;
  justify-content: space-between;
  height: 5vh;
  span {
    font-size: 2vh;
  }
  input {
    flex-basis: 40%;
  }
  button {
    border: none;
    flex-basis: 40%;
    border-radius: 28px;
    font-size: 1.8vh;
    outline: none !important;
    background-color: #f2f2f2;
  }
}
.grand-total__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3vh;
  p {
    font-size: 2.5vh;
    margin-bottom: 0;
  }
}
.save-move-plan {
  width: 100%;
  background: #f2f2f2;
  border-radius: 28px;
  border: none;
  outline: none !important;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .save-mp-svg {
    background: url("/img/common/save.svg") no-repeat center;
    background-size: contain;
    width: 3vh;
    height: 3vh;
    margin-right: 7vw;
  }
  p {
    font-size: 2.3vh;
    margin-bottom: 0;
  }
}
.create-account__container {
  padding: 16px;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 8px;
  p {
    color: #27BDE4;
  }
  .red {
    color: red;
  }
}
.account-info__row {
  position: relative;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 4vh;
  .account-info__input {
    border: none !important;
    padding-bottom: 1.3vh;
  }
  .account-info__input::placeholder {
    color: #bdbdbd;
    font-size: 2.2vh;
  }
}
.break-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-bottom: 0;
  }
}
.line {
  margin: 4vh 0;
  border-bottom: 1px solid #E0E0E0;
  width: 40%;
}
.third-party-login {
  width: 100%;
  border: 1px solid #E0E0E0;
  border-radius: 28px;
  height: 6.2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3vh;
  p {
    margin-left: 2.5vh;
    font-size: 2vh;
    font-weight: 600;
    font-family: Montserrat;
    margin-bottom: 0;
  }
}
.accept-terms__text {
  color: black;
  font-size: 1rem;
}
.accept-terms__row {
  position: relative;
  padding-left: 20px;
}
.accept-terms__link {
  color: #27BDE4;
}

////////////////// iPad ////////////////////////

@media (min-width: 768px) {
  .accept-terms__text {
    font-size: 2.3vh;
  }
  .form-check-input {
    width: 23px;
    height: 23px;
  }
  .accept-terms__text {
    padding-left: 15px;
  }
}
