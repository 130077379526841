.geosuggest__suggests {
  position: relative;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0 !important;
  padding: 0 !important;
}

.suggestions-dropdown {
  position: absolute;
  width: 88vw;
  left: 6%;
  z-index: 5;
  background: #fff;
  border: solid #829ca1;
  border-width: 0 1px 1px;
  border-radius: 0 0 2px 2px;
  -webkit-transition: opacity .2s ease-in;
  -o-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
  cursor: pointer;
  margin-bottom: 0;
  padding: 0;
}

.suggestions-list {
  list-style: none !important;
  margin: 0;
}

.suggestions-item {
  position: relative;
  -webkit-transition: background .2s ease-in-out;
  -o-transition: background .2s ease-in-out;
  transition: background .2s ease-in-out;
  padding: 8px;
  font-size: 13px;
  line-height: 18px;
  border-bottom: 1px solid #e9e9e9;
  text-decoration: none;
  list-style: none;
  span {
    padding-left: 4.5vw;
  }
}

.suggestions-item::before {
  content: "";
  display: block;
  width: 3px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  text-transform: none;
  -webkit-transition: background-color .25s;
  -o-transition: background-color .25s;
  transition: background-color .25s;
}

.suggestions-item::after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 5px;
  height: 15px;
  filter: brightness(6) hue-rotate(-70deg) saturate(0);
  width: 15px;
  background: url('/img/addresses/ic_LocationAdd.svg');
  background-size: 15px 15px;
  background-repeat: no-repeat;
}

.suggestions-item:hover svg {
  fill: #f7801e;
}

.geosuggest__item--active {
  background-color: #e2e3e5;
}

.geosuggest__item--active::after {
  filter: brightness(4) hue-rotate(190deg) saturate(.9);
}

.geosuggest__item--active::before {
  background-color: #f7801e;
}

.geosuggest__item--active svg {
  fill: #f7801e;
}

.geosuggest ul::after {
  display: block;
  // content: url(/assets/images/powered_by_google_on_white.png);
  position: absolute;
  left: -1px;
  width: calc(100% + 2px);
  height: 27px;
  text-align: end;
  padding: 4px 10px;
  background: white;
  border: 1px solid #829ca1;
  border-top: none;
}


