@import "utility/utility";
@import "vendor/vendor";
@import "variables/core";
@import "directives/index";
@import "components/index";

// html {
//   scroll-behavior: smooth !important;
// }

body {
	@include font-smoothing;
	font-size: 16px;
	font-family: "ProximaNova", sans-serif;
}

a, button, .btn {
	cursor: pointer !important;
}

.start-view {
	min-height: 640px;
}

.reschedule-request-view {
	min-height: 640px;
}

h1 {
	@include grandparent('.start-view') {
		@import './src/scss/variables/start';
		font-size: $h1-size;
	}
	@include grandparent('.moving-view-wrapper') {
		@import './src/scss/variables/moving';
		font-size: $h1-size;
    font-weight: 600;
    letter-spacing: -0.025em;
  }
}
h2 {
	@include grandparent('.start-view') {
		@import './src/scss/variables/start';
		font-size: $h2-size;
	}
	@include grandparent('.moving-view-wrapper') {
		@import './src/scss/variables/moving';
		font-size: $h2-size;
    font-weight: 600;
    letter-spacing: -0.025em;
	}
}

p {
	@include grandparent('.start-view') {
		@import './src/scss/variables/start';
		font-size: $p-size;
	}
	@include grandparent('.moving-view-wrapper') {
		@import './src/scss/variables/moving';
		font-size: $p-size;
	}
}

.well {
	background-color: #f7f7f9;
	border: 1px solid #e1e1e8;
}
.error-well {
	background-color: $red-light;
	border-color: #ff0000;
	color: #ff0000;
}

label {
	display: block;
}

input {
	outline: none;
	//user-select: text;
	&[type=radio] {
		display: inline-block;
		width: auto;
		&:checked {
			border: 1px solid #2bbdec;
		}
	}

	&[type=text] {
		width: 100%;
		border: 1px solid $panel-border-color;
		appearance: none;
		border-radius:0;
		&:focus {
			border-color: $blue !important;
		}
		&.error {
			background-color: $red-light;
			color: #ff0000;
			border-color: $red !important;
		}
		&.required {
			border-color: $green;
		}
		@include grandparent('.start-view') {
			@import 'variables/start';
			border-radius: 2px;
			background-color: white;
			font-size: em(16px);
			height: $input-height;
			&:focus {
				border-color: #C1C1C1;
				background-color: #f7f7f7;
				box-shadow: 0 0 3px #C1C1C1;
			}
			&.input-small {
				font-size: em(14px);
				height: $input-height * 0.75;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		@include grandparent('.moving-view-wrapper') {
			@import 'variables/moving';
			height: $input-height;
			line-height: $input-height;
			background-color: #f9fafb;
			padding: 0 8px;
			font-size: em(14px);
			width: 100%;
			&::placeholder {
				font-style: italic;
			}


		}
	}
	&[type=email], &[type=time], &[type=number], &[type=password] {
		@extend input[type=text];
	}
}
select {
	height: 38px;
	background-color: #f9fafb;
	padding: 0 8px;
	font-size: 0.875em;
	width: 100%;
	border-radius: 0;
	-webkit-appearance: none;
	border-color: #d6d6d6;
	&.error {
		//background-color: $red-light;
		color: #ff0000;
		border-color: $red !important;
	}
	&.required {
		border-color: $green;
	}
}
textarea {
	background-color: #f9fafb;
	font-size: 0.875em;
	width: 100%;
	border-radius: 0;
	-webkit-appearance: none;
	border-color: #d6d6d6;
	resize: none;
	&:focus {
		border: 1px solid #0073bb !important;
		outline: none;
	}
}
.datepicker-wrapper {
	position: relative;
	font-family: "ProximaNova", sans-serif;
	font-size: 16px;
	.datepicker-input {
		cursor: pointer;
		user-select: none;
		padding-left: 40px !important;
	}
	&.reschedule {
		&:before {
			height: 38px;
		}
	}
	&:before {
		content: '';
    pointer-events: none;
    display: block;
    height: 100%;
    // background-image: url('~/public/img/ic_date_V2.svg');
    background: no-repeat center;
    position: absolute;
    top: 0;
    left: 6px;
    width: 32px;
	}
	&:after {
		line-height: 64px;
		content: '▾';
		position: absolute;
		right: 9px;
		top: 0;
		font-size: em(16px);
	}
}

._720kb-datepicker-calendar {
	right: 0;
	@include grandparent('.inventory-packing-services-view') {
		right: auto;
	}
}
._720kb-datepicker-calendar-header {
	line-height: 34px;
	background: #fff !important;
	border: 1px solid rgba(0, 0, 0, 0.125);
	a {
		&:hover {
			background: none;
		}
	}
}
._720kb-datepicker-calendar-header-middle {
	color: #000;
	font-weight: 700;
}
._720kb-datepicker-calendar-month-button {
	b{
		width: 28px;
		height: 24px;
		background: transparent center top / 80%;
		overflow: hidden;
		display: inline-block;
		color: transparent;
		vertical-align: middle;
	}
	// @include grandparent('._720kb-datepicker-calendar-header-left') {
	// 	b { background-image: url('~/public/img/triangle-left.svg'); }
	// }
	// @include grandparent('._720kb-datepicker-calendar-header-right') {
	// 	b { background-image: url('~/public/img/triangle-right.svg'); }
	// }
}

.cta-fixed-bottom {
	z-index: 1000;
	height: 64px;
	width: 100%;
	font-size: em(20px);
	color: white;
	background-color: $green;
	outline: 0;
	border: 0;
}

.progress-bar {
	width: 100%;
	z-index: 500;
	background-color: white;
	border-bottom: 1px solid #d6d6d6;
	font-weight: 600;
	color: $black;
	.step-link {
		font-size: 14px;
		position: relative;
		color: black !important;
		vertical-align: middle;
		text-decoration: none !important;
	}
	.disabled {
		opacity: .4;
    outline: none;
    cursor: default;
  }
  .icon {
    fill: #89959d;
		height: 20px;
		width: 20px;
		use > svg {
			fill: black;
		}
  }  
  li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.btn-group {
	display: flex;
	flex-direction: row;
  align-items: stretch;
  height: 30px;
	.btn {
		flex-grow: 1;
    border-radius: 0;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    vertical-align: top;
    fill: #f6f6f6
		&:first-of-type {
			border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right-width: 1px !important;
		}
		&:not(:first-of-type) {
			border-left-width: 1px !important;
		}
		&:last-of-type {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}
	}
	@include grandparent('.inventory-item') {
		@import 'variables/moving';
		.btn {
			border: 1px solid $input-border-color;
			padding: 3px 0;
			white-space: nowrap;
		}
	}
}

.special-handling-table {
	th {
		white-space: nowrap;
	}
	td {
		border: 1px solid #dfdfdf;
		&.suggested {
			background: #d0ecfb;
		}
		// &.unsupported {
		// 	background: #f5f5f5;
		// }
	}
}

.special-handling-modal {
	.panel-body {
		li {
			border-bottom: 1px solid $panel-border-color;
		}
	}
}

.bid-row {
  display: flex;
  flex-wrap: wrap;
	.col-cta {
		display: flex;
		flex-direction: column;
		.btn-wrapper {
		}
	}
}

.stars {
	height: 10px;
	width: 50px;
	// background: url('~/public/img/rating-sprite-med.svg');
	vertical-align: middle;
}
.stars-unpakt {
	vertical-align: baseline;
}
.stars {
	&.stars-unpakt-0-0 { background-position: -8px -3px; }
	&.stars-unpakt-0-5 { background-position: -8px -14px; }
	&.stars-unpakt-1-0 { background-position: -8px -24px; }
	&.stars-unpakt-1-5 { background-position: -8px -35px; }
	&.stars-unpakt-2-0 { background-position: -8px -45px; }
	&.stars-unpakt-2-5 { background-position: -8px -55px; }
	&.stars-unpakt-3-0 { background-position: -8px -66px; }
	&.stars-unpakt-3-5 { background-position: -8px -76px; }
	&.stars-unpakt-4-0 { background-position: -8px -87px; }
	&.stars-unpakt-4-5 { background-position: -8px -97px; }
	&.stars-unpakt-5-0 { background-position: -8px -108px; }

	&.stars-yelp-0-0 { background-position: -62px -5px; }
	&.stars-yelp-0-5 { background-position: -62px -14px; }
	&.stars-yelp-1-0 { background-position: -62px -24px; }
	&.stars-yelp-1-5 { background-position: -62px -35px; }
	&.stars-yelp-2-0 { background-position: -62px -45px; }
	&.stars-yelp-2-5 { background-position: -62px -57px; }
	&.stars-yelp-3-0 { background-position: -62px -66px; }
	&.stars-yelp-3-5 { background-position: -62px -817px; }
	&.stars-yelp-4-0 { background-position: -62px -87px; }
	&.stars-yelp-4-5 { background-position: -62px -98px; }
	&.stars-yelp-5-0 { background-position: -62px -109px; }

	&.stars-google-0-0 { background-position: -118px -3px; }
	&.stars-google-0-5 { background-position: -118px -14px; }
	&.stars-google-1-0 { background-position: -118px -24px; }
	&.stars-google-1-5 { background-position: -118px -35px; }
	&.stars-google-2-0 { background-position: -118px -45px; }
	&.stars-google-2-5 { background-position: -118px -56px; }
	&.stars-google-3-0 { background-position: -118px -66px; }
	&.stars-google-3-5 { background-position: -118px -76px; }
	&.stars-google-4-0 { background-position: -118px -88px; }
	&.stars-google-4-5 { background-position: -118px -98px; }
	&.stars-google-5-0 { background-position: -118px -109px; }
}

.grade-block {
	padding-top: 1px;
	padding-bottom: 1px;
	border-radius: 3px;
	background-color: #00bee8;
	color: white;
	width: 25px;
}
.grade-excellent {
	background-color: #00bee8;
}
.grade-good {
	background-color: rgb(105,195,234);
}
.grade-average {
	background-color: rgb(242,124,1);
}

.mover-special {
	.total-cost {
		font-size: 14px !important;
		font-weight: 400 !important;
		text-decoration: line-through !important;
	}
}

.yelp-stars {
	height: 18px;
	width: 102px;
	// background-image: url('~/public/img/yelp-stars.png');
	background-size: 132px 560px;
	vertical-align: middle;
	overflow: hidden;
}
.yelp-stars-1-0 {
	background-position: 0px -258px;
}
.yelp-stars-1-5 {
	background-position: 0px -276px;
}
.yelp-stars-2-0 {
	background-position: 0px -294px;
}
.yelp-stars-2-5 {
	background-position: 0px -312px;
}
.yelp-stars-3-0 {
	background-position: 0px -330px;
}
.yelp-stars-3-5 {
	background-position: 0px -348px;
}
.yelp-stars-4-0 {
	background-position: 0 -366px;
}
.yelp-stars-4-5 {
	background-position: 0 -384px;
}
.yelp-stars-5-0 {
	background-position: 0 -402px;
}

.placeify-input-container {
	@include grandparent('.error') {
		.address-input {
			border-color: red;
		}
	}
	@include grandparent('.start-view') {
		position: relative;
		&:before {
			content: '';
			display: block;
			height: 100%;
			background: no-repeat center;
			position: absolute;
			top: 0;
			left: 6px;
			width: 32px;
		}
		.address-input {
			padding-left: 40px;
		}
	}
}

/* icons */
.start-view {
	/* placeify */
	.pick_up_address_input_container {
		&:before {
			// background-image: url('~/public/img/ic_from_V2.svg');
		}
	}
	.drop_off_address_input_container {
		&:before {
			// background-image: url('~/public/img/ic_to_V2.svg');
		}
	}
	/* select dropdown */
	.select-dropdown {
		cursor: pointer;
		.selection {
			padding-left: 40px;
			position: relative;
			&:before {
				content: '';
				display: block;
				height: 100%;
				// background: url('~/public/img/ic_movesize_V2.svg') no-repeat center;
				position: absolute;
				top: 0;
				left: 6px;
				width: 32px;
			}
		}
	}
	/* pikaday */
	.pikaday-container {
		position: relative;
		input {
			padding-left: 40px;
		}
		&:before {
			content: '';
			pointer-events: none;
			display: block;
			height: 100%;
			// background: url('~/public/img/ic_movesize_V2.svg') no-repeat center;
			position: absolute;
			top: 0;
			left: 6px;
			width: 32px;
		}
	}
}
.select-dropdown {
	@include grandparent('.details-view') {
		.selection {
			padding-left: 8px;
		}
	}
	@include grandparent('.book-view') {
		.selection {
			padding-left: 10px;
		}
	}
}
.pikaday-container {
	.disabled {
		color: #ababab;
		border-color: #ababab;
		background: #f0f0f0;
	}
}

.dropdown-wrapper {
	&.error {
		.selection {
			background-color: $red-light;
			color: #ff0000;
			border-color: $red !important;
		}
	}
	&.required {
		.selection {
			border-color: $green;
		}
	}
}

.page-view.details-view {
	.datepicker-wrapper {
		&:after {
			line-height: 40px;
		}
	}
}

iframe {
	height: calc(100vh - 65px);
	width: 100%;
}
.whitelabel-sidebar {
	height: calc(100vh - 65px);
}

.sign-in-tabs > a {
	display: inline-block;
	-webkit-appearance: none;
}
.sign-in-tabs > a:hover {
	text-decoration: none;
}
.tab-active {
	text-decoration: none;
}
.tab {
	border: 1px solid transparent;
	color: black;
  text-decoration: none;
  button {
    line-height: 32px;
    font-weight: 600;
    color: #000;
    font-size: 12px;
  }
}

.tab-icon svg {
	fill: #89959d;
}
.tab-icon.tab-active svg {
	fill: #333333;
}
.tab-icon.tab-active span {
	fill: #333333;
	font-weight: 600;
}


.checkbox-row {
	position: relative;
	padding: 15px 20px;
	.col-xs-2 {
		display: none;
	}
	.col-xs-10 {
		width: 100%;
	}
	input {
		&[type=checkbox], &[type=radio] {
			appearance: none;
			opacity: 0
		}
		&[type=checkbox]:checked, &[type=radio]:checked {
			background-position: 50%;
			background-repeat: no-repeat;
			background-size: 14px;
		}
	}

	.checkbox-label {
		cursor: pointer;
		padding: 0 15px 0 40px;
		position: relative;
		top: 0;
		text-align: left;
		width: 100%;
		z-index: 20;
		&:before {
			background: #fff;
			border: 2px solid #91959c;
			border-radius: 2px;
			content: "";
			height: 20px;
			left: 0;
			margin-top: -10px;
			position: absolute;
			top: 50%;
			transition: all .3s ease;
			width: 20px;
			z-index: 25;
		}
	}
	&.checked {
		.checkbox-label {
			&:before {
				background: red;
				border: 2px solid #186df7;
			}
			&:after {
				background: none;
				border: 2px solid #fff;
				border-right-style: none;
				border-top-style: none;
				content: "";
				height: 6px;
				left: 4px;
				margin-top: -4px;
				position: absolute;
				top: 50%;
				transform: rotate(-45deg);
				width: 12px;
				z-index: 30;
			}
		}
	}
}

.ripple {
	position: relative;
	overflow: hidden;
	transform: translate3d(0, 0, 0);

	&:after {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			pointer-events: none;
			background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
			background-repeat: no-repeat;
			background-position: 50%;
			transform: scale(10,10);
			opacity: 0;
			transition: transform .5s, opacity 1s;
	}

	&:active:after {
			transform: scale(0,0);
			opacity: .2;
			transition: 0s;
	}
}


// wrap input so that absolutely positioned error message doesn't overlap with element underneath.
.required-input-container {
	margin-bottom: 15px;
	.disabled {
		opacity: .6;
    outline: none;
    cursor: default;
	}
}

.bid-row .btn-primary {
	white-space: normal;
	line-height: 1.125;
}

.mobile-app-container {
	margin-top: 70px;
}


///////////// Mobile Header  ////////////////

.header-container {
	position: fixed;
	top: 0;
	width: 100%;
	height: 70px;
	line-height: 70px;
	-webkit-box-shadow: 0 4px 3px rgba(0,0,0,.26);
	box-shadow: 0 4px 3px rgba(0,0,0,.26);
	z-index: 9999;
	background: #1a3749;
	background: -webkit-gradient(linear,left top,left bottom,from(#1a3749),color-stop(13%,#1a3548),color-stop(14%,#1b364b),color-stop(42%,#193447),color-stop(44%,#1a354a),color-stop(47%,#183346),color-stop(55%,#193248),color-stop(58%,#193344),color-stop(59%,#1a3347),color-stop(60%,#183145),to(#193344));
	background: -webkit-linear-gradient(top,#1a3749 0,#1a3548 13%,#1b364b 14%,#193447 42%,#1a354a 44%,#183346 47%,#193248 55%,#193344 58%,#1a3347 59%,#183145 60%,#193344 100%);
	background: -o-linear-gradient(top,#1a3749 0,#1a3548 13%,#1b364b 14%,#193447 42%,#1a354a 44%,#183346 47%,#193248 55%,#193344 58%,#1a3347 59%,#183145 60%,#193344 100%);
	background: linear-gradient(to bottom,#1a3749 0,#1a3548 13%,#1b364b 14%,#193447 42%,#1a354a 44%,#183346 47%,#193248 55%,#193344 58%,#1a3347 59%,#183145 60%,#193344 100%);

	.logo-container {		
		display: flex;
		align-items: center;
		height: 100%;				
		position: absolute;
		padding-left: 20px;
		span {
			color: white;
			font-size: 12px;
			height: 60px;
			font-weight: 100;
		}
		.logo {
			height: 100%;
		}
	}
	.white-text {
		display: flex;
		align-items: flex-end;
	}
	.white-logo {
		background: url("/img/new_design/unpakt-header-logo.svg") no-repeat center;
    background-size: contain;
		width: 30vw;
		height: 5vh;
	}
	.white-logo--small {
		width: 23vw;
	}
	.hamburger {
		height: 100%;
		right: 0;
		//top: 31px;
	}
	.mobile-navbar {
		position: fixed;
		top: 70px;
		height: calc(100% - 70px);
		width: 75%;
		max-width: 480px;
		color: #fff;
		overflow: auto;
		-webkit-transform: translateX(-100%);
		-ms-transform: translateX(-100%);
		transform: translateX(-100%);
		-webkit-transition: -webkit-transform .3s ease-in-out;
		transition: -webkit-transform .3s ease-in-out;
		-o-transition: transform .3s ease-in-out;
		transition: transform .3s ease-in-out;
		transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
		z-index: 1;
	}
	.hamburger {
		height: 100%;
		position: absolute;
		width: 48px;
		height: 70px;
		-webkit-transition: -webkit-transform .2s;
		transition: -webkit-transform .2s;
		-o-transition: transform .2s;
		transition: transform .2s;
		transition: transform .2s,-webkit-transform .2s;
		.bar {
			position: absolute;
			top: 44%;
			width: 74%;
			height: 4px;
			background: #fff;
			-webkit-transition: background 0s .2s;
			-o-transition: background 0s .2s;
			transition: background 0s .2s;
			&:before {
				position: absolute;
				display: block;
				left: 0;
				width: 100%;
				height: 4px;
				background-color: #fff;
				content: "";
				top: -9px;
				-webkit-transition: top .2s .2s,-webkit-transform .2s 0s;
				transition: top .2s .2s,-webkit-transform .2s 0s;
				-o-transition: top .2s .2s,transform .2s 0s;
				transition: top .2s .2s,transform .2s 0s;
				transition: top .2s .2s,transform .2s 0s,-webkit-transform .2s 0s;
			}
			&:after {
				bottom: -9px;
				position: absolute;
				display: block;
				left: 0;
				width: 100%;
				height: 4px;
				background-color: #fff;
				content: "";
				-webkit-transition: bottom .2s .2s,-webkit-transform .2s 0s;
				transition: bottom .2s .2s,-webkit-transform .2s 0s;
				-o-transition: bottom .2s .2s,transform .2s 0s;
				transition: bottom .2s .2s,transform .2s 0s;
				transition: bottom .2s .2s,transform .2s 0s,-webkit-transform .2s 0s;
			}
		}
	}
}

.mobile-menu-open {
	.hamburger {
		.bar {
			background: 0 0;
			-webkit-transition: background 0s .2s;
			-o-transition: background 0s .2s;
			transition: background 0s .2s;

			&:before {
				top: 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				-webkit-transition: top .2s 0s,-webkit-transform .2s .2s;
				-o-transition: top .2s 0s,transform .2s .2s;
				transition: top .2s 0s,transform .2s .2s;
				transition: top .2s 0s,transform .2s .2s,-webkit-transform .2s .2s;
			}
			&:after {
				bottom: 0;
				-webkit-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				transform: rotate(-45deg);
				-webkit-transition: bottom .2s 0s,-webkit-transform .2s .2s;
				-o-transition: bottom .2s 0s,transform .2s .2s;
				transition: bottom .2s 0s,transform .2s .2s;
				transition: bottom .2s 0s,transform .2s .2s,-webkit-transform .2s .2s;
			}
		}
	}
}

/////////// Dimmer ////////////

.dimmer {
	position: fixed;
	top: 70px;
	height: calc(100% - 70px);
	width: 100%;
	opacity: 0;
	background-color: rgba(#000, 0.5);
	transform: translateX(-100%);
	transition: opacity 0.2s;
	z-index: 10;
}

////////// Mobile Nav Menu /////////////

.mobile-navbar {
	position: fixed;
	top: 70px;
	width: 75%;
	max-width: 480px;
	color: #fff;
	overflow: auto;
	-webkit-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
	-webkit-transition: -webkit-transform .3s ease-in-out;
	-o-transition: transform .3s ease-in-out;
	transition: transform .3s ease-in-out;
	transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
	z-index: 1;
	.menu__level {
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		overflow-y: scroll;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		list-style-type: none;
		background: #1a3749;
		background: -webkit-gradient(linear,left top,left bottom,from(#1a3749),color-stop(13%,#1a3548),color-stop(14%,#1b364b),color-stop(42%,#193447),color-stop(44%,#1a354a),color-stop(47%,#183346),color-stop(55%,#193248),color-stop(58%,#193344),color-stop(59%,#1a3347),color-stop(60%,#183145),to(#193344));
		background: -webkit-linear-gradient(top,#1a3749 0,#1a3548 13%,#1b364b 14%,#193447 42%,#1a354a 44%,#183346 47%,#193248 55%,#193344 58%,#1a3347 59%,#183145 60%,#193344 100%);
		background: -o-linear-gradient(top,#1a3749 0,#1a3548 13%,#1b364b 14%,#193447 42%,#1a354a 44%,#183346 47%,#193248 55%,#193344 58%,#1a3347 59%,#183145 60%,#193344 100%);
		background: linear-gradient(to bottom,#1a3749 0,#1a3548 13%,#1b364b 14%,#193447 42%,#1a354a 44%,#183346 47%,#193248 55%,#193344 58%,#1a3347 59%,#183145 60%,#193344 100%);
	}
	.menu__back--hidden {
		pointer-events: none;
		opacity: 0;
	}
}

/////////// Mobile nav when opened ////////////

.mobile-menu-open {
	.dimmer {
		opacity: 1;
		transform: translateX(0);
	}
	.mobile-navbar {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
}

.menu__level {
	background: #1a3749;
	background: linear-gradient(to bottom, #1a3749 0%, #1a3548 13%, #1b364b 14%, #193447 42%, #1a354a 44%, #183346 47%, #193248 55%, #193344 58%, #1a3347 59%, #183145 60%, #193344 100%);

}

.mobile-menu {
	margin: 0;
	text-transform: uppercase;
	border-bottom: 1px solid rgba(255, 255, 255, 0.06);
	line-height: 55px;
	padding-inline-start: 0;

}
.mobile-submenu {
	>.mobile-submenu-list-container {
		&.first {
			margin-top: 55px
		}
	}
}

.mobile-menu-item,
.mobile-submenu-item {

	position: relative;
	height: 55px;
	padding-left: 40px;
	border-top: 1px solid rgba(255, 255, 255, 0.06);
	border-bottom: 1px solid $menu-border-dark;
	display: flex;
	align-items: center;
	margin-bottom: 0;
  margin-top: 0;


	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background-color: $orange;
		content: '';
		transition: width 0.3s ease;
	}

	&.active {
		color: $orange;
	}

	&.active {
		&:before {
			width: 5px;
		}
	}
}

.mobile-submenu-cta-container {
	text-align: center;
}
.mobile-submenu-cta-link {
	text-transform: none;
	display: inline-block;
	width: 80%;
	margin: 20px auto;
	border: 1px solid $orangeBorderDark;
	line-height: 2.5;
	background: $orange;
	color: white;
	border-radius: 3px;
	text-shadow: 0px 1px 1px $orangeBorderDark;
}

.breadcrumb-container {
	height: 55px;
	padding: 0 20px;
	text-transform: none;
	position: fixed;
	background: $darkblue;
	width: 100%;
	z-index: 1;
	top: 0;
	left: 0;
	> * {
		display: inline-block;
		line-height: 55px;
		vertical-align: middle;
	}
	.breadcrumb-back {
		// background: url("~/public/img/left-arrow-chevron.svg") no-repeat center left / contain;
		padding-right: 40px;
		height: 20px;
	}
	.breadcrumb-current {
		font-size: 24px;
		padding-top: 1px;
	}
}

.mobile-menu-item-link,
.mobile-submenu-item-link,
.mobile-services-item-link {
	display: block;	
	color: inherit;
	opacity: .7;
}
.mobile-menu-benefits-link {
	color: $cyan;
}

.mobile-submenu-list {
	margin: 0;
}

.mobile-submenu-item {
	text-transform: none;
}
.mobile-submenu-item-link {
	line-height: 55px;
}
.mobile-submenu-list-title {
	display: block;
	background: $darkblue;
	height: 40px;
	line-height: 40px;
	padding-left: 20px;
}


input.mobile-menu-item-link {
	width: 100%;
	padding: 0;
	line-height: 55px;
	text-align: left;
	text-transform: uppercase;
	border: 0;
	outline: none;
	border-radius: 0;
	background-color: transparent;
	box-shadow: none;
	-webkit-appearance: none;
}

.logout-form,
.mobile-submenu-item-link,
.mobile-services-item-link,
.mobile-actions-menu {
	-webkit-transform: translateX(-40%);
	-ms-transform: translateX(-40%);
	transform: translateX(-40%);
	-webkit-transition: -webkit-transform 0.6s, opacity 1.2s;
	transition: transform 0.6s, opacity 1.2s;
}

.mobile-user-name-holder {
	padding-left: 40px;
	&:before {
		position: absolute;
		top: 0;
		left: 8px;
		width: 30px;
		height: inherit;
		content: '';
		// background: url('~/public/img/icon_user.svg') center center no-repeat;
		background-size: contain;
	}
}

.mobile-actions-menu {
	padding: 20px;
	border-bottom: none;
}

.mobile-users-section {
	font-size: 14px;
	&:after {
		content: '';
		display: block;
		height: 1px;
		margin: 20px 0 40px;
		background: rgba(#fff, 0.06);
		background: -moz-linear-gradient(left, rgba(#fff, 0.06) 0%, #f7801e 50%, #f7801e 51%, rgba(#fff, 0.06) 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(#fff, 0.06)), color-stop(50%, #f7801e), color-stop(51%, #f7801e), color-stop(100%, rgba(#fff, 0.06)));
		background: -webkit-linear-gradient(left, rgba(#fff, 0.06) 0%, #f7801e 50%, #f7801e 51%, rgba(#fff, 0.06) 100%);
		background: -o-linear-gradient(left, rgba(#fff, 0.06) 0%, #f7801e 50%, #f7801e 51%, rgba(#fff, 0.06) 100%);
		background: -ms-linear-gradient(left, rgba(#fff, 0.06) 0%, #f7801e 50%, #f7801e 51%, rgba(#fff, 0.06) 100%);
		background: linear-gradient(to right, rgba(#fff, 0.06) 0%, #f7801e 50%, #f7801e 51%, rgba(#fff, 0.06) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(255, 255, 255, 0.06)', endColorstr='rgba(255, 255, 255, 0.06)', GradientType=1);
	}
}
.mobile-user-name-holder {
	position: relative;
	height: 55px;
	padding-left: 40px;
	color: #f7801e;
	border-bottom: 1px solid rgba(0,0,0,.17);
	text-transform: uppercase;
	overflow: hidden;
	opacity: .7;
	height: 55px;
}


.mobile-user-menu {
	&, .mobile-menu-item:last-child {
		border-bottom: none;
	}
}

.mobile-main-menu {
	font-size: 14px;
	.mobile-menu-item:not(.active) .mobile-menu-item-link,
	.mobile-menu-item:not(.active) .mobile-services-item-link,
	.mobile-submenu-item:not(.active) .mobile-submenu-item-link {
		opacity: 0.7;
	}

	&, .mobile-menu-item:first-child {
		border-top: none;
	}
}

.mobile-action-menu-item {
	width: 50%;
  padding: 0 1rem;
}
.mobile-cta-button {
	width: 27vw;
	display: block;
	height: 50px;
	line-height: 50px;
	text-align: center;
	color: white;
	border: none;
	border-radius: 3px;
	-webkit-transition: all .25s;
	-o-transition: all .25s;
	transition: all .25s;
	background: #f57c14;
}

.menu__level {
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	overflow: hidden;
	overflow-y: scroll;
	width: calc(100%);
	height: calc(100% - 70px);
	margin: 0;
	padding: 0;
	list-style-type: none;
	ul {
		list-style: none;
	}

}

.menu__level--current {
	visibility: visible;
}

.menu__breadcrumbs {
	font-size: 0.65em;
	line-height: 1;
	position: relative;
	padding: 10px 20px;

	a {
		font-weight: bold;
		display: inline-block;
		cursor: pointer;
		vertical-align: middle;
		letter-spacing: 1px;
		text-transform: uppercase;

		&:last-child {
			pointer-events: none;
		}
		&:hover {
			color: #8182e0;
		}
	}
}

.menu__back {
	font-size: 1.05em;
	position: absolute;
	z-index: 100;
	top: 0;
	right: 2.25em;
	margin: 0;
	padding: 1.365em 0.65em 0 0;
	cursor: pointer;
	color: #2a2b30;
	border: none;
	background: none;

	&:hover,
	&:focus {
		color: #fff;
		outline: none;
	}
}

.menu__back--hidden {
	pointer-events: none;
	opacity: 0;
}

/* Open and close buttons */

.action {
	position: absolute;
	display: block;
	margin: 0;
	padding: 0;
	cursor: pointer;
	border: none;
	background: none;

	&:focus {
		outline: none;
	}
}

.action--open {
	font-size: 1.5em;
	top: 1em;
	left: 1em;
	display: none;
	color: #fff;
	position: fixed;
	z-index: 1000;
}

.action--close {
	font-size: 1.1em;
	top: 1.25em;
	right: 1em;
	display: none;
	color: #45464e;
}

.mobile-actions-menu {
	opacity: .7;
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}

.details-datepicker-position {
	right: auto !important;
	top: auto !important;
	min-width: 300px;
}

.apple-switch-container {
	background: #fff;
	background-image: initial;
	background-position-x: initial;
	background-position-y: initial;
	background-size: initial;
	background-repeat-x: initial;
	background-repeat-y: initial;
	background-attachment: initial;
	background-origin: initial;
	background-clip: initial;
	background-color: rgb(255, 255, 255);
	border: 1px solid #dfdfdf;
	position: relative;
	display: inline-block;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	overflow: visible;
	width: 35px;
	height: 20px;
	padding: 0px;
	margin: 0px;
	border-radius: 20px;
	cursor: pointer;
	-webkit-box-shadow: #dfdfdf 0px 0px 0px 0px inset;
	box-shadow: #dfdfdf 0px 0px 0px 0px inset;
	-o-transition: 0.3s ease-out all;
	transition: 0.3s ease-out all;
	-webkit-transition: 0.3s ease-out all;
	top: -1px;

}
.apple-switch-switch {
	width: 20px !important;
	height: 20px !important;
	background: #fff;
	border-radius: 100%;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
	width: 30px;
	height: 30px;
	position: absolute;
	top: 0px;
	left: 0px;
	-o-transition: 0.3s ease-out all;
	transition: 0.3s ease-out all;
	-webkit-transition: 0.3s ease-out all;
	input {
		opacity: 0;
	}
}
.apple-switch-switch.checked {
	left: 15px;
}
.apple-switch-switch input {
	opacity: 0;
}
.apple-switch-container.checked {
	background-color: $orange;
	border-color: $orange;
}

.unpakt-checkbox-container {
  position: relative;
  padding-top: 5px;
}
.unpakt-checkbox-container label {
    font-size: 11px;
    line-height: 14px;
    display: flex;
    cursor: pointer;
    position: relative;
    color: #969696;
    align-items: flex-start;
}
.unpakt-checkbox-container input {
  height: auto;
  margin-right: 5px;
}
.page-not-found {  
  .error-404 {
    padding: 40px;
    text-align: center;
    background-color: #f2f2f2;
    color: #7c7c7c;
  }
  .error-404 h1 {
    color: #7c7c7c !important;
  }
  .seperator {
    margin: 42px auto;
    display: block;
    width: 100%;
    height: 1px;
    color: #7c7c7c;
    background: linear-gradient(to right,rgba(242,242,242,.06) 0,#D9DCDD 50%,#D9DCDD 51%,rgba(242,242,242,.06) 100%);
  }
  .error-404-title {
      font-size: 30px;
      line-height: 1.2;
      margin: 0 0 22px;
      font-family: ProximaNovaLight,sans-serif;
      color: #7c7c7c;
      margin-bottom: 42px;
  }
  ul {
      display: flex;
      align-items: center;
      justify-content: center;

  }
  a {
      color: #7c7c7c;
      margin-left: 15px;
      text-decoration: underline
  }
  a:hover {
      text-decoration: none;
  }
  .helpful-links {
      margin: 0;
      font-size: 13px;
  }
}

.unpakt-datepicker {
  .icon {
    position: absolute;
    left: 4px;
    top: 0;
    z-index: 2;
    height: 62px;
    width: 32px;
    padding-bottom: 7px;
  }
}

.inventory-edit-view {
  .tabs-wrapper {
      .icon {
      height: 28px;
    }
  }
}

.list-inline {
	padding-left: 0;
	list-style: none;
	margin-left: -5px
}

.list-inline>li {
	display: inline-block;
	padding-right: 5px;
	padding-left: 5px
}

.no-style-button {
  -webkit-appearance: none;
  border: none;
  box-shadow: none;
  background: inherit;
  all: unset;
}

.add-remove-typicals {
  color: #0073bb;
  text-decoration: none;
}

.login-link {
	text-decoration: none;
	color: #f7801d;
}

.no-style-button {
  -webkit-appearance: none;
  border: none;
  box-shadow: none;
  background: inherit;
	all: unset;
	outline: none !important;
}

#card-number, #cvv, #expiration-date, #postal-code {
  height: 38px;
	line-height: 38px;
	background-color: #f9fafb;
	padding: 0 8px;
	font-size: .875em;
	width: 100%;
	border: 1px solid #d6d6d6;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
}
input[type=text]:focus {
	border-color: #0073bb!important;
}

#card-number {
  width: 250px;
}

#card-number.braintree-hosted-fields-focused, #cvv.braintree-hosted-fields-focused, #expiration-month.braintree-hosted-fields-focused, #expiration-year.braintree-hosted-fields-focused, #postal-code.braintree-hosted-fields-focused {
  border-color: #777;
}

#card-number.braintree-hosted-fields-focused, #cvv.braintree-hosted-fields-focused, #expiration-month.braintree-hosted-fields-focused, #expiration-year.braintree-hosted-fields-focused, #postal-code.braintree-hosted-fields-focused {
  border-color: tomato;
}

#card-number.braintree-hosted-fields-focused, #cvv.braintree-hosted-fields-focused, #expiration-month.braintree-hosted-fields-focused, #expiration-year.braintree-hosted-fields-focused, #postal-code.braintree-hosted-fields-focused {
  border-color: #0073bb !important;
}

.invalid {
	background-color: rgba(203, 0, 5, 0.15);
	color: #ff0000;
	border-color: #D63232 !important;
}

.paypal-buttons {
	height: 44px !important;
}

.other-payment-applied {
	overflow: hidden;
	padding: 16px;
	
	border-top: 1px solid rgb(209, 212, 214);
	border-bottom: 1px solid rgb(209, 212, 214);
}
.paypal {
	background-image: url(https://checkout.paypal.com/pwpp/2.15.7/images/paypal-small.svg), none;
	background-position: 0 50%;
	background-repeat: no-repeat;
	background-size: 20px 20px; 
}
.other-payment-cancel {
	cursor: pointer;
	text-decoration: underline;
}
.mobile-datepicker {
	-webkit-appearance: none!important;
	width: 100%;
	height: 38px;
	line-height: 38px;
	background-color: #f9fafb;
	padding: 0 8px;
	font-size: 0.875em;
	border: 1px solid #d6d6d6;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	&:after {
		display: none;
	}
}

.field-error {
	border: 1px solid red!important;
	color: red!important;
}
