.extra-services__body {
  padding: 2vh 0 6vh 0;
  h2 {
    font-size: 2.3vh;
    margin-bottom: 4.3vh;
  }
  border-bottom: 1px solid #bdbdbd;
}
.serivce__row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5vh;
  p {
    margin-bottom: 0;
    font-size: 2.2vh;
    letter-spacing: -0.3px;
  }
  position: relative;
  &.lastrow {
    padding-bottom: 3vh;
  }
}
.order-boxes__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5vw;
  span {    
    font-size: 2vh;
    color: #bdbdbd;
  }
}
.order-boxes__checkbox {
  display: flex;
  .check_square {
    width: 3vh;
    height: 3vh;
  }
  p {
    font-size: 2vh;
    margin-bottom: 0;
    padding-left: 2vw;    
  }
}
.grey-out {
  color: #d6d6d6;
}
.special-request__container {
  padding: 2vh 0;
  border-bottom: 1px solid #bdbdbd;
}
.summary__container {
  padding: 2vh 0;
}
.summary__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-bottom: 0;
    font-size: 2.3vh;
  }
  margin-bottom: 2vh;
  &.lastrow {
    border-top: 1px solid #bdbdbd;
    padding-top: 2vh;
  }
}
.summary-edit-boxes {
  color: #F57C14;
}
.packing-cost {
  color: $textGrey;
  margin-bottom: 0;
  font-size: 2vh;
  position: absolute;
  top: 3.5vh;
}
.packing-pricing-loading {
  position: absolute;
  top: 3.5vh;
}
.boxes-total {
  font-size: 2vh;
  margin-bottom: 0;
  color: #27bde4;
}

////////////// Box Delivery //////////////
.boxes-description__container {
  margin-top: 5vh;
  h1 {
    font-size: 1.1rem;
    margin-bottom: 2vh;
  }
  p {
    font-size: 1rem;
    line-height: 2.5vh;
  }
}
.box-purchase-menu__container {
  padding-left: 0;
  border-bottom: 1px solid #e0e0e0;
}
.box-purchase__row {
  display: flex;
  justify-content: space-between;
  margin: 4vh 0;
}
.box-purchase-info__container {
  display: flex;
  p {
    font-size: 2vh !important;
  }
  span {
    font-size: 1.5vh !important;
  }
  .inventory-box-icon {
    margin-top: 0.7vh;
  }
}
.box-date-picker__row {
  display: flex;
  text-align: center;
  p {
    font-size: 2.2vh;
    color: #27bde4;
    margin-bottom: 0;
    flex-basis: 30%;
    letter-spacing: -0.6px;
  }
  span {
    font-size: 2.2vh;
    font-weight: 600;
    color: #2f354c;
    letter-spacing: 0.3px;
  }
}
.box-date-picker {
  flex-basis: 50%;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  justify-content: space-evenly;
}

////////////////// iPad ////////////////////////

@media (min-width: 768px) {
  .boxes-description__container {
    h1 {
      font-size: 2.2rem;
    }
    p {
      font-size: 1.7rem;
    }
  }
  .box-date-picker__row {
    p {
      font-size: 2.2vh;

    }
    span {
      font-size: 2.2vh;

    }
  }
}
