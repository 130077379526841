.move-date__container {
  height: auto;
  margin: 2vh 0 5vh 0;
}
.move-date__inner__container {
  padding: 3%;
  border: 1px solid #f2f2f2;
  border-radius: 1vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  .address-section__title {
    color: #27bde4;
    font-family: Arial, Helvetica, sans-serif;
  }
}
.date-picker__container {
  padding: 0.6vh 0;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  justify-content: flex-start;
}
.date-picker__fake__placeholder {
  border: none !important;
  padding-left: 4vw;
  font-family: Helvetica Neue;
  font-weight: 500;
  color: #bdbdbd;
  &.darkBlue {
    color: $darkBlue;
  }
}
.calender-icon {
  background: url("/img/moveDate/calender.svg") no-repeat center;
  background-size: contain;
  width: 3vh;
  height: 3vh;
}
.date-picker-background__container {
  padding: 3% 6%;
  height: 100vh;
  background: $darkBlue;
}
.calendar__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
.close-calendar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 8vh;
  .close-calendar__icon {
    background: url("/img/moveDate/x.png") no-repeat center;
    background-size: contain;
    width: 2vh;
    height: 2vh;
  }
}

/////////// move time ////////////
.time-window__title__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2vh;
  p {
    margin-bottom: 0;
    font-family: Helvetica Neue;
    font-weight: 500;
    padding-left: 1.8vh;
  }
}
.time-window__icon {
  background: url("/img/moveDate/clock.svg") no-repeat center;
  background-size: contain;
  width: 3vh;
  height: 3vh;
}
.time-window__options__container {
  margin: 4vh 0;
  display: grid;
  grid-template-areas: 
    "button button"
    "button button";
  button {
    outline: none;
  }
}
.time-window__option__btn {
  font-size: 2.2vh;
  background-color: $normalgray;
  height: 7vh;
  margin: 1vh;
  border-radius: 27px;
  color: $darkBlue;
  border: none;
  outline: none;
  &.selected {
    background-color: $darkBlue;
    color: white;
  }
}
