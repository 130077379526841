.inventoryintro-icon {
  background: url("/img/inventory/inventory1_icon.png") no-repeat center;
  background-size: contain;
  height: 15vh;
  margin: 20px 0;
}

.info-icon {
  background: url("/img/inventory/info_icon.png") no-repeat center;
  background-size: contain;
  height: 3vh;
  width: 3vh;
}
.inventory-truck__icon {
  background: url("/img/new_design/shipping_1.png") no-repeat center;
  background-size: contain;
  grid-area: ic;
  width: 100%;
  justify-self: center;
}
.inventory-home-size__container {
  grid-area: hm;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 0.2vh;
  }
}
.inventory-total-count__container {
  grid-area: ct;
  display: flex;
  justify-content: space-around;
  .count__block {
    align-self: center;
    text-align: center;
  }
}
.inventory-edit__btn {
  grid-area: bt;
  color: white;
  background: $unpaktBlue;
  border-radius: 6px;
  border: none;
  height: 80%;
  align-self: flex-end;
  font-size: 2vh;
}
.inventory-home-size__container--row {
  margin-top: 2vh;
  span {
    margin-right: 3vw;
  }
}
.panel {
  border: none;
  height: 5;
}
.panel-heading {
  background-color: $normalgray;
  display: flex;
  align-items: center;
  height: 7vh;
  justify-content: space-between;
}
.panel-title {
  position: relative;
  cursor: pointer;
  font-size: em(14px);  
}
.panel-body {
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
}
.panel-arrow---right {
  background: url("/img/inventory/right-arrow.png") no-repeat center;
  background-size: contain;
  height: 1.4vh;
  width: 1.4vh;
}
.panel-arrow---down {
  background: url("/img/inventory/down-arrow.png") no-repeat center;
  background-size: contain;
  height: 1.4vh;
  width: 1.4vh;
}
.item-row {
  display: flex;
  justify-content: space-between;
}
.item-img {
  flex-basis: 20%;
}
.item-info {
  flex-basis: 50%;
  &.tool {
    flex-basis: 40% !important;
  }
}
.button-group {
  flex-basis: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.counter-btn {
  border: none;
  width: 3.7vh;
  height: 3.7vh;
  outline: none !important;
  &.plus {
    background: url("/img/inventory/plus.svg") no-repeat center;
    background-size: contain;
  }
  &.minus {
    background: url("/img/inventory/minus.svg") no-repeat center;
    background-size: contain;
  }
}
.counter-btn--add {
  border: none;
  flex-basis: 40%;
  height: 100%;
  outline: none !important;
  &.plus {
    background: url("/img/inventory/plus.svg") no-repeat center;
    background-size: contain;
  }
  &.minus {
    background: url("/img/inventory/minus.svg") no-repeat center;
    background-size: contain;
  }
}
.item-info {
  padding: 0;
  line-height: 2.3vh;
  display: flex;    
  justify-content: center;
  flex-direction: column;
  p {
    font-size: 2vh;
  }
}
.add-item__btn {
  margin: 2vh 0;
  background: $unpaktBlue;
  color: white;
  border: none;
  width: 100%;
  border-radius: 8px;
  height: 6.5vh;
  outline: none !important;
}

// Addinventory
.search-inventory__container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 7.2vh;
  border: 1px solid $textGray;
  border-radius: 31px;
  margin: 3vh 0;
}
.search_icon {
  background: url("/img/inventory/search.svg") no-repeat center;
  background-size: contain;
  height: 3vh;
  width: 3vh;
  flex-basis: 15%;
}
.search-inventory__input {
  height: 75%;
  border: none !important;
  flex-basis: 70%;
}
.categories-page__title {
  color: #27BDE4;
}
.category {
  display: flex;
  flex-direction: column;
}
.categories__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5vh;
  grid-row-gap: 2.5vh;
  margin-bottom: 3vh;
}
.category__image {
  height: 8vh;
  align-items: center;
  background: $normalgray;
  display: flex;
  justify-content: center;
  border-radius: 8px;
}
.category__name {
  padding-top: 1vh;
  text-align: center;
  margin-bottom: 0;
  line-height: 2.5vh;
}
.add-items__container {
  display: flex;
  flex-direction: column;
  height: auto;
}
.add-item__row {
  background: $normalgray;
  height: 8vh;
  border-radius: 8px;
  margin-bottom: 2vh;
  display: flex;
  justify-content: flex-start;
}
.add-item__img {
  display: flex;
  flex-basis: 25%;
  justify-content: center;
  align-items: center;
}
.add-item__info {
  flex-basis: 45%;
  padding: 0;
  line-height: 2.3vh;
  display: flex;    
  justify-content: center;
  flex-direction: column;
  p {
    font-size: 2vh;
  }
}
.just-added {
  flex-basis: 30%;
  color: #27BDE4;
  font-size: 1.5vh;
  margin-bottom: 0;
  align-self: center;
}
.selected-item__container {
  background: $normalgray;
  height: 30vh;
  margin-top: 3vh;
  padding: 4vh 5vh;
}
.selected-item__body {
  display: grid;
  grid-template-columns: 1fr 1fr;  
  grid-template-areas:
  "item info"
  "save save";  
}
.selected-item__img__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .selected-item__img {
    width: 110px;
    height: 110px;
  }
  grid-area: item;
}
.selected-item__counter__container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  p {
    font-size: 1.7vh;
    margin-bottom: 0;
  }
  grid-area: info;
}
.saving {
  grid-area: save;
  text-align: center;
  margin-bottom: 0;
  color: rgb(0, 216, 0);
  animation-duration: 0.3s;
  animation-name: slowIn;
}
@keyframes slowIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.saved {
  animation-duration: 1s;
  animation-name: slowOut;
}
@keyframes slowOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// PriceBasedOnInventory
.full-width__section__container {
  background: $normalgray;
  height: auto;
  padding: 3% 6%;
  width: 100vw;
  margin-bottom: 2vh;
}
.contact-us__container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5vh;
}


  ////////////////// iPad ////////////////////////

  @media (min-width: 768px) {
    
  }