.home-size__options__container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 85vh;
  margin-bottom: 2vh;
  button {
      font-size: 2.3vh;
      font-weight: 500;
      flex-basis: 9%;
      border-radius: 5vh;
      border: 1px solid #F2F2F2;
      background: $lightGray;
      font-family: "ProximaNovaBold", sans-serif;
      outline: none;
      color: black;
      span {
        color: $textGrayDark;
      }    
  }
}
.home-size-selected {
  color: #dce0e2 !important;
}
.no-mover__modal {
  margin-top: 20vh;
}