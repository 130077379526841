.hero-image__container {
    height: 55vh;
    background: url("/img/new_design/landing_3x-min.png") no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.unpakt-mainsite__container {
    height: 58vh;
    background: linear-gradient(-187deg, rgb(27, 55, 73) 56%, rgb(39, 200, 234) 42.25%);
    .get-quotes__container {
        padding-top: 4vh;
        a {
            flex-basis: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        button {
            flex-basis: 60%;
            color: white;
            background: #f7801e;
            border: none;
            height: 6vh;
            border-radius: 8px;
            font-family: ProximaNovaBold, sans-serif;
            font-weight: 700;
            box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.301);
            letter-spacing: 0.1em;
        }
    }
}
.title__container {
    padding: 5vh 5vw;    
    line-height: 2.5rem;
    font-family: ProximaNovaBold, sans-serif;
    text-align: center;
    h1 {
        font-size: 35px;
        color: white;
        font-weight: 700;
    }
    .unpakt-orange {
        color: #f67c15;
    }
    p {
        color: rgb(255, 255, 255);
        font-size: 1rem;
        line-height: 1.1;
        margin-bottom: 7px;
    }
}
.hero-image__inner__container {
    padding-bottom: 3.5vh;
    h1 {
        font-size: 9vw;
        padding-right: 18%;
        font-weight: bolder;
        color: $darkBlue;
    }
    button {
        width: 100%;
        color: white;
        background: #f7801e;
        border: none;
        height: 6vh;
        border-radius: 8px;
        font-family: ProximaNovaBold, sans-serif;
        font-weight: 700;
        box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.301);
        letter-spacing: 0.1em;
    }
}
.landing-text__container {
    font-size: 2vh;
    text-align: center;
    p {
        font-weight: 600;
    }
}
.landing-data__container {
    height: 25vh;
    max-height: 155px;
    display: flex;
    justify-content: space-between;
}
.landing-data-sqr {
    background: $lightGray;
    border-radius: 8px;
    flex-basis: 47%;
    height: 90%;
}
.landing-data-sqr__inner__container {
    height: 100%;
    width: 100%;
    padding: 18px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
        color: $darkBlue;
        margin-bottom: 0;
        text-align: center;
        font-size: 2.1vh;
    }
}
.sqr-image-1 {
    background: url("/img/new_design/Shipping_1.png") no-repeat center;
    background-size: contain;
    flex-basis: 42%;
}
.sqr-image-2 {
    background: url("/img/new_design/Landing_Success.png") no-repeat center;
    background-size: contain;
    flex-basis: 42%;
}
.sqr-context {
    flex-basis: 50%;
}
.why-book-unpakt__title__container {
    width: 100%;
    height: 11vh;
    background: $lightBlue;
}
.why-book-unpakt__title {
    h2 {
        padding-top: 16px;
        font-family: ProximaNovaRegular, sans-serif;
        text-align: center;
        margin-bottom: 0;
        font-size: 3.6vh;
    }
}
.why-book-unpakt__body {
    height: 70vh;
    background: $lightBlue;
    overflow-x: scroll;
    overflow-y: hidden;
}
.scroll-board__container {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    width: 250vw;
}
.scroll-board__container--wide {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    width: 280vw;
}
.scroll-card__container {
    background: white;
    height: 90%;
    flex-basis: 30%;
    border-radius: 10px;
}
.scroll-card__container--small {
    background: white;
    height: 90%;
    flex-basis: 23%;
    border-radius: 10px;
    border: 1px solid #bdbdbd;
}
.scroll-card__container__inner {
    height: 100%;
    padding: 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.icon-title__container--whyBook {
    flex-basis: 33%;
}
.scroll-card__list {
    display: flex;
    flex-basis: 66%;
    flex-direction: column;
    img {
        width: 16px;
        height: 11px;
    }
}
.scroll-card__img0 {
    background: url("/img/new_design/Payment_Processed.png") no-repeat center;
    background-size: contain;
    height: 10vh;
}
.scroll-card__img1 {
    background: url("/img/new_design/Done.png") no-repeat center;
    background-size: contain;
    height: 10vh;
}
.scroll-card__img2 {
    background: url("/img/new_design/Order_Completed.png") no-repeat center;
    background-size: contain;
    height: 10vh;
}

.icon-title__container {
    flex-basis: 42%;
}
.scroll-card__img3 {
    background: url("/img/new_design/icon_3.png") no-repeat center;
    background-size: contain;
    height: 15vh;
}
.scroll-card__img4 {
    background: url("/img/new_design/icon_4.png") no-repeat center;
    background-size: contain;
    height: 15vh;
}
.scroll-card__img5 {
    background: url("/img/new_design/icon_5.png") no-repeat center;
    background-size: contain;
    height: 15vh;
}
.scroll-card__img6 {
    background: url("/img/new_design/icon_6.png") no-repeat center;
    background-size: contain;
    height: 15vh;
}
.scroll-card__title {
    margin-top: 15px;
    h3 {
        text-align: center;
        font-size: 2.5vh;
        font-weight: 700;
        margin-bottom: 9px;
        color: $darkBlue;
    }
    p {
        font-size: 2vh;
        font-weight: 600;
        color: $textGrey;
        text-align: center;
    }
}
.scroll-card__context {
    flex-basis: 53%;
    p {
        font-size: 2.2vh;
        font-weight: 400;
        margin-bottom: 0;
        line-height: 3vh;
    }
}
.scroll-card__list__row {
    display: flex;
    p {
        text-align: left;
        line-height: 2.6vh;
        margin-bottom: 10px;
        font-size: 2.2vh;
        color: $darkBlue;
        letter-spacing: -0.2px;
    }
}
.scroll-card__list__row__checkmark {
    margin-right: 1vh;
}
.how-unpakt-work__title__container {
    width: 100%;
    height: 11vh;
    background: white;
}
.how-unpakt-work__body {
    height: 55vh;
    background: white;
    overflow-x: scroll;
    overflow-y: hidden;
}
.blue-panel__container {
    height: 55vh;
    background: $lightBlue;
}
.your-moving-needs__title__container {
    flex-basis: 23%;
    h2 {
        font-size: 3.6vh;
        font-family: ProximaNovaRegular, sans-serif;
        text-align: center;
    }
}
.blue-panel__buttons__container {
    flex-basis: 72%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    button {
        font-size: 2.3vh;
        font-family: Helvetica Neue;
        font-weight: 500;
        background: $lightGray;
        border: 1px solid #f2f2f2;
        flex-basis: 20%;
        border-radius: 28px;
        outline: none;
        &:active {
            background: white;
        }
    }
}
.refer-friend__container {
    height: 60vh;
    background: #fdfdfd;
}
.refer-friend__title__container {
    flex-basis: 14%;
    h2 {
        font-size: 3.6vh;
        font-family: ProximaNovaRegular, sans-serif;
        text-align: center;
        margin-bottom: 0;
    }
}
.refer-friend__body__container {
    flex-basis: 86%;
    border: 1px solid #bdbdbd;
    border-radius: 8px;
}
.refer-friend__body__inner__container {
    padding: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.refer-friend-img {
    background: url("/img/new_design/Reviews.png") no-repeat center;
    background-size: contain;
    flex-basis: 30%;
}
.refer-friend-text__container {
    flex-basis: 45%;
    text-align: center;
    p {
        font-size: 2.3vh;
        margin-bottom: 0;
    }
}
.footer__container {
    height: 38vh;
    background: $unpaktBlue;
}
.footer__inner__container {
    padding: 20px 55px 40px 55px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
        color: white;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 2vh;
        margin-bottom: 10px;
    }
}
.footer__logo--white {
    display: flex;
    justify-content: center;
    flex-basis: 40%;
    width: 90%;
    background: url("/img/new_design/unpaktLogo.png") no-repeat center;
    background-size: contain;
    align-self: center;
}
.footer-social__container {
    display: flex;
    flex-basis: 10%;
}
.question-mark {
    background: url("/img/new_design/questionMark.png") no-repeat center;
    background-size: contain;
    height: 2.8vh;
    flex-basis: 20%;
}
.footer-social--facebook {
    background: url("/img/new_design/fb.png") no-repeat center;
    background-size: contain;
    flex-basis: 25%;
}
.footer-social--instagram {
    background: url("/img/new_design/instagram.png") no-repeat center;
    background-size: contain;
    flex-basis: 25%;
}
.footer-social--linkedin {
    background: url("/img/new_design/linkedin.png") no-repeat center;
    background-size: contain;
    flex-basis: 25%;
}
.footer-social--youtube {
    background: url("/img/new_design/youtube.png") no-repeat center;
    background-size: contain;
    flex-basis: 25%;
}
.footer__button {
    flex-basis: 15%;
    border-radius: 4px;
    border: none;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
    span {
        flex-basis: 70%;
        text-align: left;
        font-weight: 500;
        font-size: 1.9vh;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    }
}

////////////////// iPad ////////////////////////

@media (min-width: 768px) {
    .landing-text__container {
        p {
            font-size: 36px;
        }
    }
    .hero-image__inner__container {
        button {
            font-size: 25px;
        }
    }
    .landing-data-sqr__inner__container {
        p {
            font-size: 32px;
        }
    }
    .landing-data__container {
        max-height: 400px;
    }
    .scroll-card__list__row {
        p {
            line-height: 3.9vh;
            margin-bottom: 15px;
            font-size: 2.4vh;
        }
    }
    .scroll-card__list {
        img {
            width: 30px;
            height: 25px;
            margin-top: 18px;
        }
    }
    .scroll-card__context {
        p {
            font-size: 2.4vh;
        }
    }
    .blue-panel__buttons__container {
        button {
            border-radius: 38px;
        }
    }
    .refer-friend__body__container {
        border-radius: 28px;
    }
    .footer__button {
        width: 70%;
        align-self: center;
    }
    .footer-social__container {
        width: 80%;
        align-self: center;
    }
}

//////////////////   Smaller Screen   //////////////////

@media (max-width: 330px) {
    .unpakt-mainsite__container {
        height: 58vh;
        background: linear-gradient(-187deg, rgb(27, 55, 73) 60%, rgb(39, 200, 234) 40%);
        .get-quotes__container {
            padding-top: 6vh;
        }
    }
    .title__container {
        h1 {
            font-size: 28px;
        }
        p {
            font-size: 0.9rem;
        }
    }
}
